@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  line-height: 1.5;
  font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1550px;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
.footer {
  background-color: #24262b;
  padding: 70px 0;
}
.footer-col {
  width: 22%;
  padding: 0 15px;
}
.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #ff9d1d;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  color: rgb(208, 208, 208);
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: RGB(255, 157, 29, 0.8);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}
.logo-Image {
  width: 34%;
}

.logo {
  width: 300px;
}

.columndiv {
  width: 22%;
}

.phargraph {
  padding: 0 10px;
  margin-top: 10px;
  font-size: 16px;
  color: rgb(208, 208, 208);
  font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43rem;
  width: 90%;
  justify-content: center;
  text-align: left;
  letter-spacing: 1px;
}

@media (max-width: 767) {
  .phargraph {
    padding: 0 10px;
    margin-top: 10px;
    font-size: 16px;
    color: rgb(208, 208, 208);
    font-family: 'Montserrat,sans-serif,Helvetica Neue,Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43rem;
    width: 100%;
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
  }
}

@media (max-width: 767px) {
  .logo-Image {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer {
    background-color: #24262b;
    padding: 70px 0 0 0;
  }
}

/*responsive*/
@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}
